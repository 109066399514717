
const aiImage =  require("../images/ai.jpg");
const dataEngineerImage =  require("../images/data-engineering.jpg");
const dataVisualizationImage =  require("../images/data-visualization.jpg");
const augmentedRealityImage =  require("../images/augmented-reality.jpg");
const calendarImage =  require("../images/calendar.jpg");
const javaImage =  require("../images/java.jpg");

export const URL = `https://api.abdarrahmane.link/`
export const API_KEY = process.env.REACT_APP_API_KEY


// Enums
enum URLKeys {
}

enum ImageKeys {
  MachineLearning = 'MachineLearning',
  DataEngineer = 'DataEngineer',
  AugmentedReality = 'AugmentedReality',
  DataVisualization = 'DataVisualization',
  Calendar = 'Calendar',
  DungeonGame = 'DungeonGame',
}

// Interfaces
interface URLsInterface {
}

interface ImageLinksInterface {
  [ImageKeys.MachineLearning]: string;
  [ImageKeys.DataEngineer]: string;
  [ImageKeys.AugmentedReality]: string;
  [ImageKeys.DataVisualization]: string;
  [ImageKeys.Calendar]: string;
  [ImageKeys.DungeonGame]: string;
}

export const urls: URLsInterface = {
};

export const imageLinks: ImageLinksInterface = {
  [ImageKeys.MachineLearning]: aiImage,
  [ImageKeys.DataEngineer]: dataEngineerImage,
  [ImageKeys.AugmentedReality]: augmentedRealityImage,
  [ImageKeys.DataVisualization]: dataVisualizationImage,
  [ImageKeys.Calendar]: calendarImage,
  [ImageKeys.DungeonGame]: javaImage,
};
