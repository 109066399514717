
import logo from './logo.svg';
import './App.css';

import { Helmet } from "react-helmet";
import React, { useContext, useEffect, useState } from "react";
// router, navigation
import { BrowserRouter as Router, Routes, Route, Link, NavLink, useMatch, useParams } from "react-router-dom";

import About from './Components/About';
import Home from './Components/Home';
import Contact from './Components/Contact';
import Article from './Components/Article';
import SignUp from './Components/SignUp';
import Logout from './Components/Logout';


function App() {

  const [user, setUser] = useState("")
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const loggedUser = localStorage.getItem("userEmail")
    setUser(loggedUser)
  }, [])
  const currentYear = (new Date().getFullYear())
  return (
    <>
      <Helmet>
        <title>About Abdarrahmane NEINE - Software Engineer</title>
        <meta name="description" content="About Abdarrahmane NEINE, a mid-level software engineer with over two years of experience. Learn more about my journey, skills, and get in touch!" />
      </Helmet>
      <div className="App" role="main">
        <header className="navbar">
          <a className="navbar-brand" href="/">Software Expert</a>
          <div className="menu-center">
            <nav>
              <ul className="navbar-list">
                <li className="navbar-item">
                  <NavLink className="navbar-link active" to="/about"
                  >About Me</NavLink>
                </li>
                <li className="navbar-item">
                  <NavLink className="navbar-link" to="/projects">Projects</NavLink>
                </li>
                <li className="navbar-item">
                  <NavLink className="navbar-link" to="/contact">Contact</NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <nav className="menu-right">
            <ul className="navbar-list">
              {user ? (
                <>
                  <li className="navbar-item">
                    <a className="navbar-link" href="/connexion">{localStorage.getItem("userEmail")}</a>
                  </li>
                  <li className="navbar-item">
                    <a className="navbar-link" href="/deconnexion">Logout</a>
                  </li>
                </>
              ) : (
                <li className="navbar-item">
                  <NavLink className="navbar-link" to="/connexion">Login</NavLink>
                </li>
              )}
            </ul>
          </nav>
          <button className="menu-toggle" aria-label="Toggle navigation menu" onClick={() => setMenuOpen(!menuOpen)}>
            ☰
          </button>
          <div className={`menu ${menuOpen ? 'active' : ''}`}>
            <nav>
              <ul className="navbar-list">
                <li className="navbar-item">
                  <NavLink className="navbar-link active" to="/about">About Me</NavLink>
                </li>
                <li className="navbar-item">
                  <NavLink className="navbar-link" to="/projects">Projects</NavLink>
                </li>
                <li className="navbar-item">
                  <NavLink className="navbar-link" to="/contact">Contact</NavLink>
                </li>
                {user ? (
                  <>
                    <li className="navbar-item">
                      <a className="navbar-link" href="/connexion">{localStorage.getItem("userEmail")}</a>
                    </li>
                    <li className="navbar-item">
                      <a className="navbar-link" href="/deconnexion">Logout</a>
                    </li>
                  </>
                ) : (
                  <li className="navbar-item">
                    <NavLink className="navbar-link" to="/connexion">Login</NavLink>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </header>
        <main className="main-content">
          <Routes>
            <Route path="/" element={<AboutMe />} />
            <Route path="/about" element={<AboutMe />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<ContactMe />} />
            <Route path="/connexion" element={<Connexion />} />
            <Route path="/deconnexion" element={<Deconnexion />} />
          </Routes>
        </main>
        <footer className="footer" aria-label="Copyright">
          © {new Date().getFullYear()} Service - Developed by Abdarrahmane NEINE
        </footer>
      </div>
    </>
  )
}


function AboutMe() {
  return (
    <>
      <section>
        <About />
      </section>
    </>
  );
}
function Connexion() {
  return (
    <>
      <section>
        <div className="about-container">
          <h1>Bien venu sur Software Expert</h1>
          <SignUp />
        </div>
      </section>
    </>
  );
}

function Deconnexion() {
  return (
    <>
      <section>
        <h1>logout</h1>
        <Logout />
      </section>
    </>
  );
}

function Projects() {
  return (
    <>
      <article>
        <Article />
      </article>
    </>
  );
}

function ContactMe() {
  return (
    <>
      <section>
        <Contact />
      </section>
    </>
  );
}
export default App;